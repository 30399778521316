import React from 'react';

export default function Clients({ children }) {
  return (
    <>
      <section className="section clients is-medium is-white has-text-centered ">
        <div className="container is-narrow">
          <div className="columns is-centered">
            <div className="column is-half">
              <h2 className="title is-size-3-desktop is-size-4-tablet is-size-5-mobile">
                Various companies I have worked for include:
              </h2>
            </div>
          </div>
          <div className="client-grid">
            <div className="columns is-multiline is-mobile">
              <div className="column is-half-mobile is-one-quarter-tablet">
                <figure className="image is-2x1">
                  <img
                    className="client-logo"
                    src="/clients/HCLLogo.png"
                    alt="HCL"
                  />
                </figure>
              </div>
              <div className="column is-half-mobile is-one-quarter-tablet">
                <figure className="image is-2x1">
                  <img
                    className="client-logo"
                    src="/clients/LeaseplanLogo.png"
                    alt="LeasePlan"
                  />
                </figure>
              </div>
              <div className="column is-half-mobile is-one-quarter-tablet">
                <figure className="image is-2x1">
                  <img
                    className="client-logo"
                    src="/clients/RiverIslandLogo.png"
                    alt="River Island Clothing Company"
                  />
                </figure>
              </div>
              <div className="column is-half-mobile is-one-quarter-tablet">
                <figure className="image is-2x1">
                  <img
                    className="client-logo"
                    src="/clients/SeeboardLogo.jpg"
                    alt="Seeboard"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
