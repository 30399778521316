import React from 'react';

export default function Hero({ hero, children }) {
  const {heading,subHeading} = hero;
  return (
    <>
      <section className="hero is-white has-text-centered">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column">
                <h2 className="title is-spaced is-size-1-desktop is-size-2-tablet is-size-3-mobile">
      {heading}
                </h2>
                <h3 className="subtitle is-size-4-desktop">
                  {subHeading}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
