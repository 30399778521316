import React from 'react';
import styled from 'styled-components';

const SkillsStyles = styled.section`
  margin-top: -75px;
`;
const Skill = ({skill}) => {
  const skillItems = skill.items;
  return(
    <div className="column">
      <h3 className="title">{skill?.title}</h3>
      <p className="list-title has-text-primary has-text-weight-bold">
                  {skill?.description}
                </p>
                <ul>
                  {skillItems.map((item,index) => (
                  <li key={item}>{item}</li>
                  ))}
                </ul>
    </div>
  )
}

export function Skillsx({ skills, children }) {
  return (
    <>
      <SkillsStyles className="section skills has-text-centered ">
        <div className="container is-narrow">
          <div className="box">
            <div className="columns is-centered">
              <div className="column">
                <h3 className="title">Roles</h3>
                <p className="list-title has-text-primary has-text-weight-bold">
                  Roles undertaken:
                </p>
                <ul>
                  <li>Technical Architect</li>
                  <li>Software Engineer</li>
                  <li>Consultant</li>
                  <li>Development Lead</li>
                  <li>Team Leader</li>
                  <li>Scrum Master</li>
                </ul>
              </div>
              <div className="column">
                <h3 className="title">Skills</h3>
                <p className="list-title has-text-primary has-text-weight-bold">
                  Experienced in the following:{' '}
                </p>
                <ul>
                  <li>Agile</li>
                  <li>Oracle RDBMS</li>
                  <li>PL/SQL</li>
                  <li>SQL Server</li>
                  <li>T/SQL</li>
                  <li>ASP.Net MVC</li>
                  <li>ASP.Net Webforms</li>
                  <li>C#</li>
                  <li>PHP</li>
                  <li>MySQL</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>Javascript</li>
                  <li>Python</li>
                  <li>Bash</li>
                  <li>Wordpress</li>
                </ul>
              </div>
              <div className="column">
                <h3 className="title">Certifications</h3>
                <p className="list-title has-text-primary has-text-weight-bold">
                  Professional Qualifications:{' '}
                </p>
                <ul>
                  <li>Professional Scrum Master (PSM 1)</li>
                  <li>
                    Open University - Introduction to cyber security stay safe
                    online
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </SkillsStyles>
    </>
  );
}


export default function Skills({ skills, children }) {
  return (
    <>
      <SkillsStyles className="section skills has-text-centered ">
        <div className="container is-narrow">
          <div className="box">
            <div className="columns is-centered">
            {
              skills.map(skill => (
                <Skill key={skill.id} skill={skill} />
              ))
            }
              </div>
          </div>
        </div>
      </SkillsStyles>
    </>
  );
}
