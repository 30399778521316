import React from 'react';
import styled from 'styled-components';

const AboutStyles = styled.section`
  background-color: LightBlue;
`;
export default function About({ about, children }) {
  const intro = about.intro.intro;
  return (
    <>
      <AboutStyles className="section about is-medium has-text-centered">
        <div className="container">
          <div className="columns is-centered">
            <div className="subtitle is-size-4-desktop">
              {intro}
            </div>
          </div>
        </div>
      </AboutStyles>
    </>
  );
}
