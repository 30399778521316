import React from 'react';
import {graphql} from 'gatsby';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import About from '../components/About';
import Clients from '../components/Clients';
import Skills from '../components/Skills';

const HomePage = (props) => {
  const skills=props.data.HomePage.edges[0].node.experienceDetail;
  const pagedata=props.data.HomePage.edges[0].node;
  return (
    <>
      <SEO title="home" />
      <Hero hero={pagedata}/>
      <About about={pagedata}/>
      <Skills skills={skills} />
      <Clients />
    </>
  );
}

export default HomePage;

export const homePageQuery = graphql`
  query {
    HomePage: allContentfulHomePage {
      edges {
        node {
          id
        subHeading
        heading
        intro {
          intro
        }
          experienceDetail {
            id
            items
            title
            description
          }
        }
      }
    }
  }`
